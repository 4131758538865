*,*::after,*::before{
  border: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  display: flex;
  gap: 20px;
  background: linear-gradient(to bottom right,#020210,#0A3638);
  
}
.list{
  list-style-type: none;
  padding: 14px;
  font-family: serif;
  font-size: 20px;
  color: white;
  border-top: .2px solid rgb(95, 92, 92);
  border-bottom: .2px solid rgb(95, 92, 92);
}

#nav{
  position: fixed;
  top: 200px;
  width:10%;
  text-align:center
}
.list:hover{
  color:#64FFDA; 
}

.active{
  color: #64FFDA;
  border-left: 4px solid #64FFDA;
  padding-left: 10px;
  }
#intro{
  width:78%;
  padding:0 4%;
}

#intro h1{
  /* font-size:3.5em; */
  font-size: calc(.2rem + 3.3vw);
  font-family:monospace;
  line-height:25px;
  word-spacing: -10px;
  text-overflow: clip;
  padding: 7px 0;
  background-color: #052330;
  border-bottom-right-radius: 50px;
}
/*  
#container>h1{
  padding: 1px;
  margin-top: 0;
  margin-bottom: 0;
  border: 1px solid red;
  height: 100%;
} */
/* 
#container>h1::before{
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  background-color: #052330; 
  border-left:4px solid #64FFDA;
  animation: intr 4s steps(24) infinite;
}
@keyframes intr{
  40%,60%{
      left: 47%;
  }
  100%{
      left:0%
  }
} */
.customImage{
  height: 70px;
  width: 70px;
}
#intro p{
  font-size:22px;
  font-family: Open Sans;
  color:#7cb9bc;
  letter-spacing:2px;
  padding-right:20%;
  line-height:32px;
}
#introMain{
  display:flex;
  border-bottom:1px solid white;
  padding:10% 0;
}
#profileDiv{
  width: 22%;
  display: flex;
  align-items: center;
}
.profile{
  border-radius: 50%;
  height: 73%;
  width: 100%;
}
.about{
  width: 88%;
  display:flex;
  gap: 3%;
  padding:8% 0%;
  border-bottom:1px solid white;
}
.about #des{
  width:68%;
  padding:0 0% 0 4%;
  font-size:20px;
  letter-spacing:2px;
  line-height:35px
 }
 #titleDiv{
  width:22%;
 }
 .git{
  font-size: auto;
 }
 #calenderDiv:hover,#git1:hover,#git2:hover{
  transform: scale(1.04);
  transition: all 700ms;
 }

 #gitStatis{
  display: flex;
  padding: 4% 10%;
  gap: 10px;
 }
 #git1,#git2{
  width: 100%;
  height: 100%;
  box-shadow :rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
 }

 .title{
  font-size:3.2em;
  letter-spacing: -.2px;
  word-spacing: -4px;
  font-family:monospace;
  color:#64FFDA;
  text-shadow: 4px 4px black;
}

#skillMain{
  display:flex;
  padding:8% 0 6% 0;
  border-bottom:1px solid white;
}

#skillNew{
  width: 80%;
}

#barChart{
  width:58%;
  margin:auto
}

#projectRes{
  width: 85%;
  display:grid;
  grid-template-columns:repeat(2,1fr);
  gap:5%;
  row-gap: 30px;
  text-align:center;
  margin:auto;
}


#image{
  position: fixed;
  top: 6.5%;
  left: 1%; 
}

#image img{
  height: 100% ;
  width: 100%;
}


.content{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  animation: gayab 800ms linear forwards;
  animation-delay: 1500ms;
}
@keyframes gayab{
0%{
  opacity: 1;
  top: 50%;
  left: 50%;
}
100%{
  opacity: 0.2;
  box-shadow: #91d5f0ed 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  top: 12%;
  left: 5%;
}
}

.projectDiv>.projectImp{
  height:250px;
  width:100% 
}
#contactMain{
  display:flex;
  justify-content:space-between;
  padding:8% 0;
  border-top:1px solid white;
  flex-direction: row;
}
#contactMe{
  width:45%;
}
#location{
  width:50%;
  padding-right:10%
}
#skillDiv{
  display:grid;
  grid-template-columns:repeat(4,1fr);
  gap:6%;
  font-size:22px;
  row-gap:30px;
  text-align:center;
  width:100%;
  padding:0 5%
}
@media only screen and (max-width: 1200px) {
  #profileDiv{
    align-items: flex-start;
  }
.profile{
  align-items: flex-start;
  height: 65%;
}
#barChart{
  width:60%;
  margin:auto
}
#gitStatis{
  display: block;
  text-align: center;
 }
 #git1{
  margin-bottom: 20px;
 }
}

@media only screen and (max-width: 960px) {
  #intro h1{
    word-spacing: -5px;
  }
  #image{
    width: 117px;
  }
  .profile{
    height: 50%;
  }
   #image img{
    width: 75%;
  }
  .list{
    font-size: 16px;
   }
   #intro p{
    font-size: 20px;
    line-height: 30px;
  }
   .about p{
    font-size: 19px;
    line-height: 30px;
   }
   #skillDiv p{
    font-size: 15px;
   }
   #barChart{
    width:70%;
    margin:auto
  }
   #title{
    font-size: 2.8em;
  }
  #projectRes{
    width: 85%;
    margin: auto;
  }
  .projectDiv>.projectImp{
    height:220px;
  }
} 


@media only screen and (max-width: 720px) {
  #intro h1{
    word-spacing: -4px;
  }
  #image{
    width: 90px;
  }
  #image img{
   width: 57%;
 }
 .profile{
  height: 40%;
}
 .list{
  font-size: 13px;
 }
 .main{
      opacity: 0;
 }
 #intro p{
  font-size: 18px;
  line-height: 24px;
  padding-right: 15%;
}
 .about p{
  font-size: 16px;
  line-height: 24px;
 }
 #skillDiv p{
  font-size: 12px;
  gap: 7%;
 }
 #barChart{
  width:80%;
  margin:auto
}
#title{
  font-size: 2.4em;
}
#contact p{
  font-size: 10px;
}
#projectRes{
  width: 92%;
  margin: auto;
}
.projectDiv>.projectImp{
  height:190px;
}
  #skillDiv{
    grid-template-columns: repeat(3,1fr);
    width: 100%;
    gap: 3%;
    row-gap: 20px;
    padding-left: 10%;
  }
} 


@media only screen and (max-width: 540px) {
  #intro h1{
    border: 1px solid rgb(35, 65, 65);
    font-size: 22px;
  }
  #nav{
    width: 50px;
  }
  #introMain{
    display: block;
    width: 100%;
    padding-left: 10px;
  }
  #intro{
    width: 88%;
    padding: 0;
  }
  #profileDiv{
    width: 100%;
  }
  .profile{
    width: 36%;
    height: 150px;
  }
  .about{
    display: block;
    width: 88%;
  }
  .git{
    font-size: 14px;
   }
   #git1{
    margin-bottom: 10px;
   }
  .about #des{
    width:90%;
   }
   #titleDiv{
    width: 100%;
    padding-left: 10px;
    margin-bottom: 15px;
   }
   #skillMain{
    display: block;
   }
   #barChart{
    width:95%;
    margin:auto
  }
  .customImage{
    height: 50px;
    width: 45px;
  }
  .content{
    width: 95px;
    height:7px
  }
  #image{
    top: 22px;
    width: 67px;
  }
  #image img{
   width: 42%;
   height: 85%;
 }
 .list{
  font-size: 13px;
  padding: 12px 0px;
 }
 .active{
  border-left: 3px solid #64FFDA;
  padding-left: 3px;
  }
 .main{
      opacity: 0;
 }
 #intro p{
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  padding-right: 0;
}
 .about p{
  font-size: 14px;
  line-height: 20px;
 }
 
#title{
  font-size: 2em;
}
#contact p{
  font-size: 8px;
}
#projectRes{
  width: 95%;
  margin: auto;
  grid-template-columns: repeat(1,1fr);
  row-gap: 24px;
}
.projectDiv>.projectImp{
  height:160px;
}
#contactMain{
  flex-direction: column;
}
#contactMe{
  width:85%;
  margin: auto;
}
#location{
  width:85%;
  margin: auto;
  padding: 0;
}
#skillDiv{
  grid-template-columns: repeat(3,1fr);
  width: 100%;
  gap: 3%;
  row-gap: 20px;
  padding-left: 10%;
}
#skillNew{
  width: 95%;
}
} 

@media only screen and (max-width: 380px){
  #barChart{
    width: "100%";
  }
}

#intro span{
  cursor: pointer;
}
#intro span:hover{
  position: relative;
  top: 0px;
  left: 0px;
  bottom: 0px;
  animation-delay: 1500ms;
  animation: letter 1.5s linear forwards;
}
.defaultColor{
  color: #64FFDA;
}
@keyframes letter{
  0%{
  top: 0px;
  left: 0px;
  }
  33%{
    top: -7px;
    left: 7px;
  }
  66%{
    top: 5px;
    left: -5px;
  }
  100%{
    top: 0px;
    left: 0;
  }
}


.logo{
  color: #64FFDA;
  font-size: 28px;
  transition: font-size 300ms;
}
.logo:hover{
  font-size: 34px;
}

.name{
  transform: rotate(45deg);
  font-family: 'Times New Roman', Times, serif;
  font-size: 120%;
  animation: nam 2s linear forwards;
  animation-delay: 2s;
}
@keyframes nam{
  0%{
      opacity: 1;
  }
  100%{
      opacity: 0;
  }

}

#image>div{
  height:80px;
  width:150px;
  padding:5px;
  /* background-image: url("../public/LogoMe.png");
  background-size: cover;
  background-position: center; */
  opacity: 0;
  animation: logo 1s linear forwards;
  animation-delay: 2300ms;
}
@keyframes logo{
  0%{
      opacity: 0;
  }
  100%{
      opacity: 1;
  }
}

.main{
  transform: rotate(45deg);
  position: relative;
  width: 120px;
  height: 60px;
  display: inline-block;
  text-align: center;
}
.main::before{
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: 0;
  height: 0;
  background: transparent;
  border: 2px solid transparent;
  animation-delay: 1.5s;
  animation: hey 1500ms linear forwards;
}
.main::after{
  content: "";
  position: absolute;
  bottom: -2px;
  left: -2px;
  width: 0;
  height: 0;
  background: transparent;
  border: 3px solid transparent;
  animation-delay: 1.5s;
  animation: hey2 1500ms linear forwards;
}

#nameLogo a{
  position: relative;
  text-decoration: none;
  width: 120px;
  height: 60px;
  text-align: center;
  line-height: 50px;
  display: inline-block;
  font-size: 24px;
  text-transform: uppercase;
  color: #64FFDA;
  transform: rotate(270deg);
  padding: 8px;
}

#nameLogo a::before{
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: 0;
  height: 0;
  background: transparent;
  border: 3px solid transparent;
  animation-delay: 1.5s;
  animation: hey 1500ms linear forwards;
}

@keyframes hey {
  0% {
    width: 0;
    height: 0;
    border-top-color: #64FFDA;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  50% {
    width: 100%;
    height: 0;
    border-top-color: #64FFDA;
    border-right-color: #64FFDA;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  100% {
    width: 100%;
    height: 100%;
    border-top-color: #64FFDA;
    border-right-color: #64FFDA;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

#nameLogo a::after{
  content: "";
  position: absolute;
  bottom: -2px;
  left: -2px;
  width: 0;
  height: 0;
  background: transparent;
  border: 3px solid transparent;
  animation-delay: 1.5s;
  animation: hey2 1500ms linear forwards;
}
@keyframes hey2 {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #64FFDA;
  }
  50% {
    width: 100%;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #64FFDA;
    border-left-color: #64FFDA;
  }
  100% {
    width: 100%;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #64FFDA;
    border-left-color: #64FFDA;
  }
}

#Hbody{
  opacity: 0.05;
  animation: main 2s linear forwards;
  animation-delay: 2.5s;
}
@keyframes main{
  0%{
    opacity: 0.05;
  }
  100%{
    opacity: 1;
  }
}
.skillLogo{
  font-size: 35px;
}
#contact input{
    background-image: linear-gradient(to right, black,#376E79);
    height: 50px;
    font-size: 16px;
    color: #b1aeae;
    padding: 0 20px;
    margin-bottom: 15px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.823) 0px 5px 15px;
}
#contact #inLine{
  display: flex;
  gap: 3%;
}
#contact #message{
  min-height: 150px;
}
#contact button{
  margin-top: 10px;
  height: 45px;
  font-size: 16px;
  padding: 2px 15px;
  float: right;
  background: #0b2835;
  border-radius: 5%;
  color: whitesmoke;
  border: 1px solid #64FFDA;
  cursor: pointer;
}
button:hover{
  box-shadow:#64FFDA 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#skillDiv>div{
border: 1px solid #64FFDA;
border-radius: 10px;
box-shadow: #64FFDA 0px 5px 15px;
width: 80%;
padding: 5%;
transform: scale(1);
transition: 1s transform;
}
#skillDiv>div:hover{
  transform: scale(1.1);
}
.projectDiv{
  padding:6%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 15px;
  position: relative;
  top: 0;
  left: 0;
  transition: 1s top;
  transition: 1s left;
}
.projectDiv:hover{
  top: 10px;
  left: 10px;
  box-shadow: rgba(105, 147, 203, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
